/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class Veiculo {
  constructor () {
    this.veiculo_id = uuidV4()
    this.nfe_compra= ''
    this.dt_compra= ''
    // this.dt_venda= ''
    this.modelo_id= ''
    this.marca_id= ''
    this.cor_id= ''
     // this.vendedor_id= ''
     // this.comprador_id= ''
     this.proprietario_id= ''
     this.fornecedor_id= ''
     this.atendente= ''
     this.tp_veiculo= ''
     this.ano_modelo= ''
     this.chassi= ''
     this.placa= ''
     this.renavan= ''
     this.km= ''
    // this.vl_venda= ''
    this.comunicacao= false
    this.intencao= false
    this.vl_compra= ''
    this.pendencias= ''
    this.observacoes= ''
    this.venda_id= ''
    this.despesas_veiculos= []

  }
}

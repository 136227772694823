/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class Duplicata {
  constructor () {
    this.duplicata_id = uuidV4()
    this.participante_id= ''
    this.dt_emissao= ''
    this.dt_vencimento= ''
    this.vl_duplicata= ''
    this.dt_quitacao= ''
    this.observacoes= ''
    this.venda_id= ''
  }
}

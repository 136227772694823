/* eslint-disable */
import {createStore} from 'vuex'
import geral from './modules/geral'
import token from "./modules/token";
import cores from "./modules/cores";
import marcas from "./modules/marcas";
import caixas from "./modules/caixas";
import vendas from "./modules/vendas";
import modelos from "./modules/modelos";
import usuarios from './modules/usuarios'
import veiculos from "./modules/veiculos";
import auditoria from "./modules/auditoria";
import duplicatas from "./modules/duplicatas";
import municipios from "./modules/municipios";
import configuracao from "./modules/configuracoes";
import participantes from "./modules/participantes";
import eventos_caixas from "./modules/eventos_caixas";
import contas_receber from "./modules/contas_receber";
import formas_pagamento from "./modules/formas_pagamento";
import movimentos_caixas from "./modules/movimentos_caixas";

export default createStore({
   modules: {
      geral,
      token,
      cores,
      caixas,
      vendas,
      marcas,
      modelos,
      usuarios,
      veiculos,
      auditoria,
      duplicatas,
      municipios,
      configuracao,
      participantes,
      eventos_caixas,
      movimentos_caixas,
      formas_pagamento,

   }
})

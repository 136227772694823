/* eslint-disable */
import {pegaDados, processar} from '../../services/GenericAPI'
import {Filtro} from "../../models/filtro/filtro";
import {BaseSCH} from "../../search/BaseSCH";
import {Configuracao} from "../../models/configuracao";

const url = '/configuracao'

const state = {
  configuracao: new Configuracao(),
  filtro: new Filtro()
}

const getters = {
  pegaConfiguracao: (state) => {
    return state.configuracao
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
}

const actions = {
  async getItem({commit}, {id, onSucesso,onErro}) {
    try {
      var filtro = BaseSCH(1)
      const list = (await pegaDados(url+'/get', filtro)).data
      if (list.data[0] !== undefined) {
        commit('SET_CONFIGURACAO', list.data[0])
        onSucesso()
      } else {
        onErro()
      }
    } catch (error) {
      onErro()
      console.log(error)
    }
  },

  async processarConfiguracao({commit}, {acao,onSucesso,onErro}) {
    try {
      let res = await processar(url + '/commit', state.configuracao, acao)

      this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso()
    } catch (error) {
      onErro()
      console.log('error: ',error)
      this.dispatch('geral/setObjMsgError', error.response.data)
    }
  },

}

const mutations = {
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },
  SET_CONFIGURACAO(state, configuracao) {
    state.configuracao = configuracao
  },

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

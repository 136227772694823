/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class Participante {
  constructor () {
    this.participante_id = uuidV4()
    this.cpf_cnpj = ''
    this.nome = '' // razao social
    this.apelido = '' //fantasia
    this.fone1 = ''
    this.fone2 = ''
    this.email = ''
    this.cep = ''
    this.endereco = ''
    this.numero = ''
    this.complemento = ''
    this.bairro = ''
    this.municipio_id = ''
    this.tp_participante = null



  }
}

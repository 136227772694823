import {Filtro} from "../models/filtro/filtro";

export const VendaSCH = (perPage) => {
  let filtro = new Filtro();
  filtro.perPage = perPage;
  filtro.fields = ['*'];
  filtro.addOrder('dt_venda','desc')
  filtro.addOrder('numero','desc')
  return filtro
};


import {Filtro} from "../models/filtro/filtro";

export const VeiculoSCH = (perPage) => {
  let filtro = new Filtro();
  filtro.perPage = perPage;
  filtro.fields = ['*'];
   filtro.addOrder('dt_compra','desc')
   filtro.addOrder('dt_venda','desc')

  return filtro
};

/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class EventoCaixa {
  constructor () {
    this.evento_caixa_id = uuidV4()
    this.descr = ''
    this.tp_movto = 'C'
    this.tp_evento = 'V'
  }
}

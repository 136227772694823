import {createRouter, createWebHistory} from 'vue-router'

const router = createRouter({
   history: createWebHistory(process.env.BASE_URL),
   routes: [
      {path: '/', name: 'Login',meta:{title: 'Login'}	, component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue')},
      {
         path: '/dash',
         name: 'Dash',
         component: () => import(/* webpackChunkName: "Dash" */ '../views/Dash.vue'),
         children: [

            {
               path: '/principal',
               name: 'Principal',
               meta:{title: 'Principal'},
               component: () => import(/* webpackChunkName: "about" */ '../views/Principal.vue')
            },
            // CADASTRO
            {path: '/cores',name: 'Cores',meta:{title: 'Cores'},component: () => import(/* webpackChunkName: "Cores" */ '../views/Cadastros/cores/Cores.vue')},
            {path: '/evento_caixa',name: 'EventoCaixa',meta:{title: 'Evento Caixa'},component: () => import(/* webpackChunkName: "about" */ '../views/Cadastros/evento_caixa/EventoCaixa.vue')},
            {path: '/marcas',name: 'Marcas',meta:{title: 'Marcas'},component: () => import(/* webpackChunkName: "Marcas" */ '../views/Cadastros/marcas/Marcas.vue')},
            {path: '/modelos',name: 'Modelos',meta:{title: 'Modelos'},component: () => import(/* webpackChunkName: "Modelos" */ '../views/Cadastros/modelos/Modelos.vue')},
            {path: '/caixas',name: 'Caixas',meta:{title: 'Caixas'},component: () => import(/* webpackChunkName: "Caixas" */ '../views/Cadastros/caixa/Caixa.vue')},
            {path: '/municipios',name: 'Municipios',meta:{title: 'Municipios'},component: () => import(/* webpackChunkName: "Municipios" */ '../views/Cadastros/municipios/Municipio.vue')},
            {path: '/participante',name: 'Participante',meta:{title: 'Participante'},component: () => import(/* webpackChunkName: "Participante" */ '../views/Cadastros/participante/Participante.vue')},
            {path: '/participante/cadastro',name: 'ParticipanteCadastro',meta:{title: 'Participante'},component: () => import(/* webpackChunkName: "ParticipanteCadastro" */ '../views/Cadastros/participante/ParticipanteCadastro.vue')},
            {path: '/usuario',name: 'Usuário',meta:{title: 'Usuários'},component: () => import(/* webpackChunkName: "about" */ '../views/Cadastros/usuarios/Usuario.vue')},
            {path: '/forma_pagamento',name: 'FormaPagamento',meta:{title: 'Pagamento'},component: () => import(/* webpackChunkName: "about" */ '../views/Cadastros/formas_pagamentos/FormasPagamento.vue')},
            // LANCAMENTOS
            {path: '/veiculos',name: 'Veiculos',meta:{title: 'Veiculos'},component: () => import(/* webpackChunkName: "Veiculos" */ '../views/Cadastros/veiculos/Veiculos.vue')},
            {path: '/veiculos/cadastro',name: 'VeiculoCadastro',meta:{title: 'Veiculo'},component: () => import(/* webpackChunkName: "VeiculoCadastro" */ '../views/Cadastros/veiculos/VeiculoCadastro.vue')},
            // FINANCEIRO
            {path: '/movimento_caixa',name: 'MovimentoCaixa',meta:{title: 'Movimento de Caixa'},component: () => import(/* webpackChunkName: "ContasPagas" */ '../views/Financeiro/movimento_caixa/MovimentoCaixa.vue')},
            {path: '/contas_receber',name: 'ContasReceber',meta:{title: 'Contas a Receber'},component: () => import(/* webpackChunkName: "ContasReceber" */ '../views/Financeiro/duplicatas/Duplicatas.vue')},
            {path: '/venda',name: 'Venda',meta:{title: 'Venda'},component: () => import(/* webpackChunkName: "Venda" */ '../views/Financeiro/venda/Venda.vue')},
            {path: '/vender',name: 'Vender',meta:{title: 'Venda'},component: () => import(/* webpackChunkName: "VendaCadastro" */ '../views/Financeiro/venda/VendaCadastro.vue')},
            // CONFIGURACAO
            {path: '/configuracao',name: 'Configuracao',meta:{title: 'Configuração'},component: () => import(/* webpackChunkName: "Configuracao" */ '../views/Configuracao/Configuracao.vue')},

         ]
      },
      {
         path: '/:pathMatch(.*)*',
         name: 'not-found',
         component: () => import(/* webpackChunkName: "404" */ '../views/NotFound/NotFound.vue')
      },

   ]
});
router.beforeEach((to, from) => {
   document.title = (to.meta?.title + ' - ' + 'Giro Motos')  ?? 'Giro Motos'
})

export default router

/* eslint-disable */
import {lista, nao_autorizado, pegaDados, processaArquivo, processar} from '../../services/GenericAPI'
import {Filtro} from "../../models/filtro/filtro";
import {Venda} from '../../models/venda'
import {getBaseSCH} from "../../search/BaseSCH";
import {VendaSCH} from "../../search/VendaSCH";

const url = '/vendas'

const state = {
  all: [],
  venda: new Venda(),
  filtro: new Filtro()
}

const getters = {
  listaVendas: (state) => {
    return state.all
  },
  pegaVenda: (state) => {
    return state.venda
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
}

const actions = {
  searchItem({commit}, search) {
    state.filtro.filters.search = [];
    state.filtro.filters.addSearch(['numero','participante'], '%' + search + '%', 'ilike')
  },
  async getItem({commit}, {venda_id, onSucesso,onErro}) {
    try {
      var filtro = getBaseSCH('venda_id', venda_id, '=')
      const list = (await pegaDados(url+'/get', filtro)).data
      if (list.data[0] !== undefined) {
        commit('SET_VENDA', list.data[0])
        onSucesso()
      } else {
        onErro()
      }
    } catch (error) {
      onErro()
      console.log(error)
    }
  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', VendaSCH(perPage))
  },
  async setAllVendas({commit},{onSucesso, onErro}) {
    try {
      const list = (await pegaDados(url + '/get_view', state.filtro)).data
      commit('SET_VENDAS', list)
      onSucesso()
    } catch (error) {
      console.log(error)
      onErro()
      nao_autorizado(error)
    }
  },
  async getSaldo({commit},{filtro,onSucesso, onErro}) {
    try {
      const list = (await pegaDados(url + '/get_saldo', filtro)).data
      onSucesso(list[0])
    } catch (error) {
      console.log(error)
      onErro()
      nao_autorizado(error)
    }
  },
  async getSaldoMeses({commit},{filtro,onSucesso, onErro}) {
    try {
      const list = (await pegaDados(url + '/get_saldo_meses', filtro)).data
      // console.log('getSaldoMeses: ',list);
      // Faturamento 2024
      // saldo: 0

      onSucesso(list)
    } catch (error) {
      console.log(error)
      onErro()
      nao_autorizado(error)
    }
  },
  async processarVenda({commit}, {acao,onSucesso,onErro}) {
    try {
      let res = await processar(url + (acao === 'gravar' ? '/commit' : '/delete'), state.venda, acao)

      this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso()
    } catch (error) {
      onErro()
      console.log('error: ',error)
      this.dispatch('geral/setObjMsgError', error.response.data)
    }
  },

  async printResumoVenda({commit}, {filtro,onSucesso, onErro}) {
    try {
      const list = (await processaArquivo(url + '/get_resumo_venda', filtro))
      onSucesso(list)
    } catch (error) {
      console.log('Erro',error)
      onErro()
      nao_autorizado(error)
    }
  },


  async printPedido({commit}, {filtro,onSucesso, onErro}) {
    try {
      const list = (await processaArquivo(url + '/emitir_pedido', filtro))
      onSucesso(list)
    } catch (error) {
      console.log('Erro',error)
      onErro()
      nao_autorizado(error)
    }
  },

  async deleteDuplicatas({commit}, {venda,onSucesso,onErro}) {
    try {
      let res = await processar(url + '/commit_del_dup', venda, 'gravar')

      this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso()
    } catch (error) {
      onErro()
      console.log('error: ',error)
      this.dispatch('geral/setObjMsgError', error.response.data)
    }
  },
  async processarObs({commit}, {acao,onSucesso,onErro}) {
    try {
      let res = await processar(url + '/commit_obs', state.venda, acao)

      this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso()
    } catch (error) {
      onErro()
      console.log('error: ',error)
      this.dispatch('geral/setObjMsgError', error.response.data)
    }
  },
  setVenda({commit}, venda) {
    commit('SET_VENDA', venda)
  },
}

const mutations = {

  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },
  SET_VENDAS(state, vendas) {
    state.all = vendas
  },
  SET_VENDA(state, venda) {
    state.venda = venda
  },

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

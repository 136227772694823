/* eslint-disable */
import {lista, nao_autorizado, pegaDados, processar} from '../../services/GenericAPI'
import {Filtro} from "../../models/filtro/filtro";
import {FormaPagamento} from '../../models/forma_pagamento'
import {BaseDescSCH, getBaseSCH, BaseKeySCH} from "../../search/BaseSCH";

const url = '/forma_pagamento'

const state = {
  all: [],
  allList: [],
  forma_pagamento: new FormaPagamento(),
  filtro: new Filtro()
}

const getters = {
  listaAllFormaPagamentos: (state) => {
    return state.allList
  },

  listaFormaPagamentos: (state) => {
    return state.all
  },
  pegaFormaPagamento: (state) => {
    return state.forma_pagamento
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
}

const actions = {
  searchItem({commit}, search) {
    state.filtro.filters.search = [];
    state.filtro.filters.addSearch(['descr'], '%' + search + '%', 'ilike')
  },
  async getItem({commit}, {forma_pagamentos_id, onSucesso,onErro}) {
    try {
      var filtro = getBaseSCH('forma_pagamento_id', forma_pagamentos_id, '=')
      const list = (await pegaDados(url+'/get', filtro)).data
      if (list.data[0] !== undefined) {
        commit('SET_FORMA_PAGAMENTO', list.data[0])
        onSucesso()
      } else {
        onErro()
      }
    } catch (error) {
      onErro()
      console.log(error)
    }
  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', BaseKeySCH(perPage,'forma_pagamento_id','asc'))
  },

  async setAllListFormaPagamentos({commit},{onSucesso, onErro}) {
    try {
      const list = (await lista(url + '/all')).data
      commit('SET_ALL_LIST', list)
      onSucesso()
    } catch (error) {
      console.log(error)
      onErro()
    }
    },
  async setAllFormaPagamentos({commit},{onSucesso, onErro}) {
    try {
      const list = (await pegaDados(url + '/get', state.filtro)).data
      commit('SET_FORMA_PAGAMENTOS', list)
      onSucesso()
    } catch (error) {
      console.log(error)
      onErro()
      nao_autorizado(error)
    }
  },
  async processarFormaPagamento({commit}, {acao,onSucesso,onErro}) {
    try {
      let res = await processar(url + (acao === 'gravar' ? '/commit' : '/delete'), state.forma_pagamento, acao)

      this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso()
    } catch (error) {
      onErro()
      console.log('error: ',error)
      this.dispatch('geral/setObjMsgError', error.response.data)
    }
  },
  setFormaPagamento({commit}, forma_pagamento) {
    commit('SET_FORMA_PAGAMENTO', forma_pagamento)
  },
}

const mutations = {

  SET_ALL_LIST(state, filtro) {
    state.allList = filtro
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },
  SET_FORMA_PAGAMENTOS(state, forma_pagamentos) {
    state.all = forma_pagamentos
  },
  SET_FORMA_PAGAMENTO(state, forma_pagamento) {
    state.forma_pagamento = forma_pagamento
  },

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

/* eslint-disable */
import {lista, nao_autorizado, pegaDados, processar} from '../../services/GenericAPI'
import {Filtro} from "../../models/filtro/filtro";
import {Participante} from '../../models/participante'
import {BaseDescSCH, getBaseSCH} from "../../search/BaseSCH";
import {ParticipanteSCH} from "../../search/ParticipanteSCH";

const url = '/participante'

const state = {
   all: [],
   allList: [],
   participante: new Participante(),
   filtro: new Filtro()
}

const getters = {
   listaAllParticipantes: (state) => {
      return state.allList
   },

   listaParticipantes: (state) => {
      return state.all
   },
   pegaParticipante: (state) => {
      return state.participante
   },
   pegaFiltro: (state) => {
      return state.filtro
   }
}

const actions = {
   searchItem({commit}, search) {
      state.filtro.filters.search = [];
      state.filtro.filters.addSearch(['nome', 'email', 'fone1', 'fone2'], '%' + search + '%', 'ilike')
   },
   async getItem({commit}, {participante_id, onSucesso, onErro}) {
      try {
         var filtro = getBaseSCH('participante_id', participante_id, '=')
         const list = (await pegaDados(url + '/get', filtro)).data
         commit('SET_PARTICIPANTE', list.data[0])
         onSucesso()
      } catch (error) {
         onErro()
         console.log(error)
      }
   },

   setFiltro({commit}, perPage) {
      commit('SET_FILTRO', ParticipanteSCH(perPage))
   },

   async setAllListParticipantes({commit}, {onSucesso, onErro}) {
      try {
         const list = (await lista(url + '/all')).data
         commit('SET_ALL_LIST', list)
         onSucesso()
      } catch (error) {
         console.log(error)
         onErro()
      }
   },

   async setListParticipantesComVenda({commit}, {onSucesso, onErro}) {
      try {
         const list = (await lista(url + '/all_com_venda')).data
         commit('SET_ALL_LIST', list)
         onSucesso()
      } catch (error) {
         console.log(error)
         onErro()
      }
   },
   async setAllParticipantes({commit}, {onSucesso, onErro}) {
      try {
         const list = (await pegaDados(url + '/get', state.filtro)).data
         commit('SET_PARTICIPANTES', list)
         onSucesso()
      } catch (error) {
         console.log(error)
         onErro()
         nao_autorizado(error)
      }
   },
   async processarParticipante({commit}, {acao, onSucesso, onErro}) {
      try {
         let res = await processar(url + (acao === 'gravar' ? '/commit' : '/delete'), state.participante, acao)

         this.dispatch('geral/setMsgSuccess', res.data.message)
         onSucesso()
      } catch (error) {
         onErro()
         console.log('error: ', error)
         this.dispatch('geral/setObjMsgError', error.response.data)
      }
   },
   setParticipante({commit}, participante) {
      commit('SET_PARTICIPANTE', participante)
   },
}

const mutations = {

   SET_ALL_LIST(state, filtro) {
      state.allList = filtro
   },
   SET_FILTRO(state, filtro) {
      state.filtro = filtro
   },
   SET_PARTICIPANTES(state, participantes) {
      state.all = participantes
   },
   SET_PARTICIPANTE(state, participante) {
      state.participante = participante
   },

}

export default {
   namespaced: true,
   state,
   getters,
   actions,
   mutations
}

import {Filtro} from "../models/filtro/filtro";


export const ModeloSCH = (perPage) => {
  let filtro = new Filtro();
  filtro.perPage = perPage;
  filtro.fields = ['*'];
  return filtro
};

export const ModeloDescSCH = (perPage) => {
  let filtro = new Filtro();
  filtro.perPage = perPage;
  filtro.fields = ['modelos.descr','marcas.descr as marca', 'modelos.marca_id'];
  filtro.join.addLeft('marcas', 'marcas.marca_id', 'modelos.marca_id');
  filtro.addOrder('modelos.descr', 'asc')
  return filtro
};
export const BaseKeySCH = (perPage, key, direct) => {
  let filtro = new Filtro();
  filtro.perPage = perPage;
  filtro.fields = ['*'];
  filtro.addOrder(key, direct)
  return filtro
};

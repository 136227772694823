import {Filtro} from "../models/filtro/filtro";

export const DuplicataSCH = (perPage) => {
  let filtro = new Filtro();
  filtro.perPage = perPage;
  filtro.fields = ['*'];
   filtro.addOrder('dt_emissao','desc')

  return filtro
};

export const  getDuplicataSCH = (campo, valor, condicao)=>{
   let filtro = new Filtro();
   filtro.perPage = '*';
   filtro.filters.addFilter(campo,valor,condicao);
   filtro.fields = ['*'];
   return filtro
};

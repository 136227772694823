/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class Configuracao {
  constructor () {
    this.configuracao_id = uuidV4()
    this.cnpj = ''
    this.razao = ''
    this.fantasia = ''
    this.fone1 = ''
    this.fone2 = ''
    this.email = ''
    this.cep = ''
    this.endereco = ''
    this.numero = ''
    this.complemento = ''
    this.bairro = ''
    this.municipio_id = ''
    this.email = ''
    this.site = ''
  }
}




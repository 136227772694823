/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class MovimentoCaixa {
  constructor () {
    this.movimento_caixa_id = uuidV4()
    this.evento_caixa_id = ''
    this.caixa_id = ''
    this.descr_compl = ''
    this.dt_movto = ''
    this.vl_movto = ''
    this.observacoes = ''
  }
}

/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class DespesaVeiculo {
  constructor (id) {
    this.despesa_veiculo_id = uuidV4()
    this.caixa_id= ''
    this.veiculo_id= id
    this.evento_caixa_id= ''
    this.vl_despesa= ''
  }
}

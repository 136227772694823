/* eslint-disable */
import { v4 as uuidV4 } from 'uuid';
export class Venda {
  constructor () {
    this.venda_id = uuidV4()
    this.participante_id= ''
    this.dt_venda= ''
    this.numero= ''
    this.vl_venda= ''
    this.vendedor_id= ''
    this.observacoes= ''
    this.qtd_parcelas= 1
    this.inter_parcelas= 30
    this.forma_pagamento_id= ''
    this.vendas_itens= []
  }
}

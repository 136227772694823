import {Filtro} from "../models/filtro/filtro";

export const MovimentoCaixaSCH = (perPage) => {
  let filtro = new Filtro();
  filtro.perPage = perPage;
  filtro.fields = ['movimento_caixa.movimento_caixa_id', 'movimento_caixa.caixa_id', 'movimento_caixa.evento_caixa_id',
     'descr_compl', 'dt_movto', 'vl_movto', 'observacoes',
     'caixas.descr as caixa', 'eventos_caixa.descr as evento', 'eventos_caixa.tp_movto'];
   filtro.join.addLeft('caixas', 'caixas.caixa_id', 'movimento_caixa.caixa_id');
   filtro.join.addLeft('eventos_caixa', 'eventos_caixa.evento_caixa_id', 'movimento_caixa.evento_caixa_id');

  return filtro
};


